import Vue from "vue";
import Router from "vue-router";
import Home from "@/components/Home";
import Artists from "@/components/Artists";
import About from "@/components/About";
import SingleArtist from "@/components/SingleArtist";
import Song from "@/components/Song";
import Users from "@/components/Users";
import NotFound from "@/components/NotFound";
import Payments from "@/components/Payments";
import ReferrerRegistration from "@/components/ReferrerRegistration";
import store from "@/store";
import { getUser, checkUserSubscription } from "@/services/users";
import UserSetup from "@/components/UserSetup";
import UserEdit from "@/components/UserEdit";
import UserPreview from "@/components/UserPreview";
import ResetPassword from "@/components/ResetPassword";
import ResetPasswordRequest from "@/components/ResetPasswordRequest";
import AdminSubscription from "@/components/AdminSubscription";
import BuySubscription from "@/components/BuySubscription";
import Referrals from "@/components/Referrals";
import MakeMoney from "@/components/MakeMoney.vue";
import Playlists from "@/components/Playlists";
import SinglePlaylist from "@/components/Playlist";
import Videos from "@/components/Videos.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/artists",
      name: "artists",
      component: Artists,
    },
    {
      path: "/about",
      name: "about",
      component: About,
    },
    {
      path: "/artist/:name",
      name: "single_artist",
      component: SingleArtist,
      props: true,
    },
    {
      path: "/artist/:name/:songName/:id",
      name: "song",
      component: Song,
      props: true,
    },
    {
      path: "/videos",
      name: "videos",
      component: Videos,
      props: true,
    },
    {
      path: "/registration/referrer/:refId",
      name: "referrer_registration",
      component: ReferrerRegistration,
      props: true,
    },
    {
      path: "/user/setup/:regUrl",
      name: "user_setup",
      component: UserSetup,
      props: true,
    },
    {
      path: "/password/reset/:resetUrl",
      name: "reset_password",
      component: ResetPassword,
      props: true,
    },
    {
      path: "/password/request/reset",
      name: "reset_password_request",
      component: ResetPasswordRequest,
    },
    {
      path: "/user/edit/",
      name: "user_edit",
      component: UserEdit,
      props: true,
    },
    {
      path: "/user/",
      name: "user_preview",
      component: UserPreview,
      props: true,
    },
    {
      path: "/users/",
      name: "users",
      component: Users,
    },
    {
      path: "/admin/subscription",
      name: "admin_subscription",
      component: AdminSubscription,
    },
    {
      path: "/admin/payments",
      name: "payments",
      component: Payments,
    },
    {
      path: "/admin/referrals",
      name: "referrals",
      component: Referrals,
    },
    {
      path: "/make-money",
      name: "make_money",
      component: MakeMoney,
    },
    {
      path: "/user/subscription",
      name: "buy_subscription",
      component: BuySubscription,
    },
    {
      path: "/playlists",
      name: "playlists",
      component: Playlists,
    },
    {
      path: "/playlists/:name",
      name: "single_playlist",
      component: SinglePlaylist,
    },
    {
      path: "*",
      name: "404",
      component: NotFound,
    },
  ],
  scrollBehavior: function (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (to.hash) {
          return resolve({ selector: to.hash });
        } else if (savedPosition) {
          return resolve(savedPosition);
        } else {
          resolve(document.getElementById("app").scrollIntoView({ behavior: "smooth" }));
        }
      }, 400);
    });
  },
});

router.beforeEach(function (to, from, next) {
  const privatePages = [
    "user_edit",
    "user_preview",
    "users",
    "buy_subscription",
    "admin_subscription",
    "payments",
    "referrals",
    "videos",
    "playlists",
    "make_money"
  ];
  const subscriptionPages = [
    "user_edit",
    "user_preview",
    "users",
    "admin_subscription",
    "payments",
    "referrals",
    "videos",
    "playlists",
    "make_money"
  ];
  const adminPages = ["users", "admin_subscription", "payments", "referrals"];
  const authRequired = privatePages.includes(to.name);
  const adminRequired = adminPages.includes(to.name);
  const subscriptionRequired = subscriptionPages.includes(to.name);

  const isLogged = store.state.isLogged;
  const isAdmin = store.state.user.role === "legatus";
  const loggedIn = localStorage.getItem("karaoke-user-token");

  if (authRequired) {
    if (isLogged && loggedIn) {
      if (subscriptionRequired) {
        checkUserSubscription(function (data) {
          if (data.error) {
            if (data.payload.data.error_code === 2) {
              return next("/user/subscription");
            } else {
              store.state.isLogged = false;
              localStorage.removeItem("karaoke-user-token");
              return next("/");
            }
          } else {
            if (adminRequired) {
              if (isAdmin) {
                return next();
              } else return next("/404");
            } else {
              return next();
            }
          }
        });
      } else {
        if (adminRequired) {
          if (isAdmin) {
            return next();
          } else return next("/404");
        } else {
          return next();
        }
      }
    } else if (loggedIn && !isLogged) {
      if (subscriptionRequired) {
        checkUserSubscription(function (data) {
          if (data.error) {
            if (data.payload.data.error_code === 2) {
              return next("/user/subscription");
            } else {
              store.state.isLogged = false;
              localStorage.removeItem("karaoke-user-token");
              return next("/");
            }
          } else {
            getUser(function (data) {
              if (data.error) {
                store.state.isLogged = false;
                localStorage.removeItem("karaoke-user-token");
                return next({ path: from.path, query: { showLogin: true } });
              } else {
                store.state.isLogged = true;
                store.state.user = data.payload;
                const isAdmin = store.state.user.role === "legatus";
                if (adminRequired) {
                  if (isAdmin) {
                    return next();
                  } else return next("/404");
                } else {
                  return next();
                }
              }
            });
          }
        });
      } else {
        if (adminRequired) {
          if (isAdmin) {
            return next();
          } else return next("/404");
        } else {
          return next();
        }
      }
    } else {
      store.state.isLogged = false;
      return next({ path: from.path, query: { showLogin: true } });
    }
  } else {
    if (loggedIn && !isLogged) {
      if (subscriptionRequired) {
        checkUserSubscription(function (data) {
          if (data.error) {
            if (data.payload.data.error_code === 2) {
              return next("/buy-subscription");
            } else {
              store.state.isLogged = false;
              localStorage.removeItem("karaoke-user-token");
              return next("/");
            }
          } else {
            getUser(function (data) {
              if (data.error) {
                store.state.isLogged = false;
                localStorage.removeItem("karaoke-user-token");
                return next({ path: from.path, query: { showLogin: true } });
              } else {
                store.state.isLogged = true;
                store.state.user = data.payload;
                return next();
              }
            });
          }
        });
      } else {
        getUser(function (data) {
          if (data.error) {
            store.state.isLogged = false;
            localStorage.removeItem("karaoke-user-token");
            return next({ path: from.path, query: { showLogin: true } });
          } else {
            store.state.isLogged = true;
            store.state.user = data.payload;
            return next();
          }
        });
      }
    } else {
      return next();
    }
  }
});

export default router;
