<template>
  <div class="make-money">
    <div class="make-money__wrapper">
      <h1>{{ $t("make_money.description_1") }}</h1>
      <h3>{{ $t("make_money.description_2", { prize: '4.99€' }) }}</h3>
      <p>{{ $t("make_money.description_3", { prize: '4.99€' }) }}</p>
      <p>{{ $t("make_money.description_4") }}</p>
      <p>{{ $t("make_money.description_5") }}</p>
      <p>{{ $t("make_money.description_6") }}</p>
      <div class="make-money__referral">
        <label>{{ $t("my_referrer_url") }}</label>
        <div class="make-money__referral--url">
          <input id="copyRefUrl" :value="refUrl" readonly />
          <button class="copy-btn" @click="toggleRefCopy">
            <copy-icon />
          </button>
        </div>
      </div>
      <p>{{ $t("make_money.description_7", { prize: '4.99€' }) }}</p>
      <p>{{ $t("make_money.description_8") }}</p>
      <h3>{{ $t("make_money.description_9") }}</h3>
    </div>
  </div>
</template>

<script>
import CopyIcon from "@/components/svg/CopyIcon.vue";

export default {
  components: {CopyIcon},
  data() {
    return {
      snackbar: {
        showSnackbar: false,
        color: "success",
        message: "This is snackbar",
      },
    };
  },
  methods: {
    toggleRefCopy: function () {
      let textToCopy = document.getElementById("copyRefUrl");
      this.showSnackbar("Url kopiran!", "info");
      textToCopy.select();
      document.execCommand("copy");
    },
    showSnackbar: function (message, color) {
      this.snackbar.color = color;
      this.snackbar.message = message;
      this.snackbar.showSnackbar = true;
    },
  },
  computed: {
    refUrl() {
      return (
          window.location.origin +
          `/registration/referrer/${this.loggedUser.refUrl}`
      );
    },
    loggedUser() {
      return this.$store.state.user;
    },
  }
}
</script>
